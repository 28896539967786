<template>
  <CustomModal
    :name="modalName"
    width="732px"
    :classes="['resourcesSettingModal']"
    @before-close="reset"
    @before-open="handleShow"
  >
    <div class="resourcesSettingModal__close">
      <Icon
        name="close"
        is-clickable
        color="secondary-500"
        @click="$modal.hide(modalName)"
      />
    </div>
    <template v-slot:header>
      <span class="resourcesSettingModal__title"> Set lanes rules </span>
    </template>
    <div class="resourcesSettingModal">
      <Form v-slot="{ invalid }">
        <FormItem rules="required|maxValue:100">
          <Input
            v-model="percent"
            v-mask="'###'"
            label="Closing condition (%)"
            placeholder="Closing condition (%)"
          />
        </FormItem>
        <div class="resourcesSettingModal__row">
          <Button
            is-block
            is-outlined
            variant="secondary"
            @click="$modal.hide(modalName)"
          >
            {{ $t("Cancel") }}
          </Button>
          <Button
            :is-disabled="invalid"
            :is-loading="isLoading"
            is-block
            @click="$emit('save', percent)"
          >
            {{ $t("Save") }}
          </Button>
        </div>
      </Form>
    </div>
  </CustomModal>
</template>
<script>
export default {
  name: "ResourcesSettingModal",
  props: {
    value: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    modalName: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    percent: "",
  }),
  methods: {
    handleShow() {
      this.percent = this.value;
    },
    reset() {
      this.$emit("reset");
      this.percent = "";
    },
  },
};
</script>
<style lang="scss">
.vm--container {
  .resourcesSettingModal.vm--modal {
    overflow: auto;
    position: relative;
    padding: 40px 24px 24px;
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 48px);
    max-width: 732px;
  }
}
</style>

<style lang="scss" scoped>
.resourcesSettingModal {
  &__slotsLabel {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: $secondary-500;
    margin-bottom: 40px;

    &.standard {
      margin-bottom: 24px;
    }
  }

  &__row {
    display: grid;
    gap: 0 40px;
    grid-template-columns: 1fr 1fr;
    margin-top: 48px;

    @media (max-width: $media-laptop - 1) {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }
}
</style>
