<template>
  <div class="resources-page">
    <div class="resources-page__titleWrapper">
      <Title :count="resources.length">Lane management</Title>
      <Guard permission="venues.edit" v-slot="{ isAvailable }">
        <Icon
          v-if="isAvailable"
          name="settings"
          is-clickable
          @click="$modal.show(settingsModalName)"
        />
      </Guard>
    </div>
    <div class="grid-2">
      <Guard permission="resources.create" v-slot="{ isAvailable }">
        <CreateButton v-if="isAvailable" @click="handleCreate">
          Create lane
        </CreateButton>
      </Guard>
      <template v-if="!isLoading">
        <ResourceCard
          v-for="item in resources"
          :key="item.id"
          :title="item.id"
          :capacity="item.capacity"
          :is-paused="item.isPaused"
          :description="item.description"
          :type="item.type"
          :is-loading="loadingResourceId === item.id"
          :is-deleting="deletingResourceId === item.id"
          @pause="handlePause(item)"
          @remove="handleRemove(item)"
          @edit="handleEdit(item, true)"
          @duplicate="handleEdit(item, false)"
        />
      </template>
    </div>
    <Loader v-if="isLoading" class="mx-auto" size="m" color="primary" />
    <div v-else-if="!resources.length" class="empty">
      You haven't created lanes yet
    </div>
    <Guard
      permission="resources.create"
      v-slot="{ isAvailable: isCreateAvailable }"
    >
      <Guard
        permission="resources.edit"
        v-slot="{ isAvailable: isEditAvailable }"
      >
        <ResourceFormDialog
          v-if="isCreateAvailable || isEditAvailable"
          :modal-name="modalName"
          :value="editedResource"
          :is-editing="isEditing"
          :is-submitting="isSubmitting"
          @submit="handleSubmit"
        />
      </Guard>
    </Guard>
    <Guard permission="venues.edit" v-slot="{ isAvailable }">
      <ResourcesSettingModal
        v-if="isAvailable"
        :value="closurePercent"
        :is-loading="isSettingsLoading"
        :modal-name="settingsModalName"
        @save="handleSettingsSave"
      />
    </Guard>
    <div class="resources-page__fixed" data-v-step="19" />
    <div class="resources-page__fixed" data-v-step="21" />
  </div>
</template>

<script>
import CreateButton from "@/components/common/CreateButton";
import ResourceCard from "@/components/resources/ResourceCard";
import { mapActions, mapState } from "vuex";
import ResourceFormDialog from "@/components/resources/ResourceFormDialog";
import { NEW_RESOURCE_MOCK } from "@/helpers/mocks";
import dialog from "@/plugins/dialog";
import ResourcesSettingModal from "@/components/resources/ResourcesSettingModal";
import Guard from "@/components/common/Guard";
import { eventBus } from "@/helpers/eventBus";

export default {
  name: "ResourcesPage",
  components: {
    Guard,
    ResourcesSettingModal,
    ResourceFormDialog,
    ResourceCard,
    CreateButton,
  },
  data() {
    return {
      settingsModalName: "resources-settings-modal",
      isLoading: false,
      isSettingsLoading: false,
      modalName: "resourceFormDialog",
      isEditing: false,
      newItem: { ...NEW_RESOURCE_MOCK },
      loadingResourceId: null,
      deletingResourceId: null,
      isSubmitting: false,
    };
  },
  computed: {
    ...mapState({
      resources: (state) => state.resources.list,
      closurePercent: (state) => state.venues.selectedVenue.closurePercent,
      editedItem: (state) => state.resources.editedItem,
    }),
    editedResource() {
      return this.isEditing ? this.editedItem : this.newItem;
    },
  },
  async created() {
    try {
      this.isLoading = true;
      await this.fetchResources();
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions({
      fetchResources: "resources/fetchResources",
      fetchResourceById: "resources/fetchResourceById",
      createResource: "resources/createResource",
      updateResource: "resources/updateResource",
      deleteResource: "resources/deleteResource",
      setClosurePercent: "resources/setClosurePercent",
    }),
    handleCreate() {
      this.isEditing = false;
      this.newItem = { ...NEW_RESOURCE_MOCK };
      eventBus.$emit("createResourceModalOpened");
      this.$modal.show(this.modalName);
    },
    async handleEdit(resource, isEditing) {
      try {
        this.loadingResourceId = resource.id;
        await this.fetchResourceById(resource.id);
        if (!isEditing) {
          this.newItem = { ...this.editedItem, id: "" };
        }
        this.isEditing = isEditing;
        this.$modal.show(this.modalName);
      } finally {
        this.loadingResourceId = null;
      }
    },
    async handlePause(resource) {
      try {
        this.loadingResourceId = resource.id;
        await this.updateResource({
          id: resource.id,
          data: { isPaused: !resource.isPaused },
        });
        await this.fetchResources();
      } finally {
        this.loadingResourceId = null;
      }
    },
    async handleRemove(resource) {
      const confirmed = await dialog.confirm({
        title: "Delete lane?",
        message: "All lane data will be lost",
        okText: "Delete",
        cancelText: "Cancel",
      });
      if (confirmed) {
        try {
          this.deletingResourceId = resource.id;
          await this.deleteResource(resource.id);
          await this.fetchResources();
        } finally {
          this.deletingResourceId = null;
        }
      }
    },
    async handleSubmit({ id, ...data }) {
      try {
        this.isSubmitting = true;
        if (this.isEditing) {
          await this.updateResource({ id, data });
        } else {
          await this.createResource({ id, ...data });
        }
        await this.fetchResources();
        this.$modal.hide(this.modalName);
      } finally {
        this.isSubmitting = false;
      }
    },
    async handleSettingsSave(percent) {
      try {
        this.isSettingsLoading = true;
        await this.setClosurePercent(percent);
      } catch (e) {
        console.log(e);
      } finally {
        this.isSettingsLoading = false;
        this.$modal.hide(this.settingsModalName);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.resources-page {
  display: flex;
  flex-direction: column;
  gap: 40px;

  &__titleWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__fixed {
    position: fixed;
    bottom: 10px;
    right: 10px;
  }
}
</style>
