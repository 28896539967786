<template>
  <div class="resource-card">
    <div class="resource-card__top">
      <div class="resource-card__title">
        <Title :level="2">{{ title }}</Title>
        <span class="resource-card__title__capacity">
          Capacity: {{ capacity }}
        </span>
      </div>
      <div class="resource-card__activity">
        <span
          :class="[
            'resource-card__activity__status',
            isPaused ? 'm-paused' : 'm-running',
          ]"
        >
          {{ isPaused ? "paused" : "active" }}
        </span>
        <Guard permission="resources.pause" v-slot="{ isAvailable }">
          <Icon
            v-if="isAvailable"
            :name="isPaused ? 'play' : 'pause'"
            color="secondary-400"
            is-clickable
            @click="$emit('pause')"
          />
        </Guard>
      </div>
    </div>
    <p class="resource-card__description">
      {{ description || "&nbsp;" }}
    </p>
    <div class="resource-card__bottom">
      <span class="resource-card__type">
        Type: <span class="resource-card__type__name">{{ type }}</span>
      </span>
      <div class="resource-card__actions">
        <Guard permission="resources.delete" v-slot="{ isAvailable }">
          <Icon
            v-if="isAvailable && isPaused"
            name="trash"
            color="secondary-400"
            is-clickable
            @click="$emit('remove')"
          />
        </Guard>
        <Guard permission="resources.edit" v-slot="{ isAvailable }">
          <Icon
            v-if="isAvailable"
            name="edit"
            color="secondary-400"
            is-clickable
            @click="$emit('edit')"
          />
        </Guard>
        <Guard permission="resources.create" v-slot="{ isAvailable }">
          <Icon
            v-if="isAvailable"
            name="clipboard"
            color="secondary-400"
            is-clickable
            @click="$emit('duplicate')"
          />
        </Guard>
      </div>
    </div>
    <div v-if="isLoading || isDeleting" class="overlay-loader">
      <Loader size="m" :color="isDeleting ? 'danger' : 'primary'" />
    </div>
  </div>
</template>

<script>
import Guard from "@/components/common/Guard";
export default {
  name: "ResourceCard",
  components: { Guard },
  props: {
    title: {
      type: String,
      default: "",
    },
    capacity: {
      type: [Number, String],
      default: 0,
    },
    isPaused: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
    },
    isDeleting: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.resource-card {
  padding: 16px;
  box-shadow: $box-shadow-small;
  border: 2px solid $secondary-400;
  border-radius: 8px;
  position: relative;
  overflow: hidden;

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    margin-bottom: 12px;
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 16px;
    white-space: nowrap;

    &__capacity {
      font-size: 14px;
      line-height: 24px;
      color: $primary;
    }
  }

  &__activity {
    display: flex;
    align-items: center;
    gap: 10px;

    &__status {
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;

      &.m-paused {
        color: $danger;
      }

      &.m-running {
        color: $success;
      }
    }
  }

  &__description {
    font-size: 16px;
    line-height: 24px;
    color: $secondary-500;
    margin-bottom: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__bottom {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__type {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: $secondary-400;

    &__name {
      color: $secondary-500;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
</style>
