<template>
  <CustomModal
    :name="modalName"
    :classes="['resource-form-dialog']"
    @before-close="$emit('close')"
  >
    <div class="resource-form-dialog__close">
      <Icon
        name="close"
        is-clickable
        color="secondary-500"
        @click="$modal.hide(modalName)"
      />
    </div>
    <template v-slot:header>
      <Title :level="2" class="mx-auto mb-32">
        {{ isEditing ? "Edit lane" : "Create lane" }}
      </Title>
    </template>
    <Form v-slot="{ invalid, changed }" data-v-step="20">
      <div class="grid-2 mb-32">
        <FormItem
          rules="required|alpha-num-space-dash-underscore"
          :custom-error="idErrorText"
          v-slot="{ isError }"
        >
          <div v-if="isIdVerifying" class="overlay-loader">
            <Loader size="s" color="primary" />
          </div>
          <Input
            v-model="form.id"
            :label="$t('Unique lane name')"
            :placeholder="$t('Unique lane name')"
            :isError="isError || !!idErrorText"
            :isDisabled="isEditing"
          />
        </FormItem>
        <FormItem rules="required|numeric" v-slot="{ isError }">
          <Input
            v-model="form.capacity"
            :label="$t('Capacity')"
            :placeholder="$t('Capacity')"
            :isError="isError"
          />
        </FormItem>
        <FormItem rules="numeric" v-slot="{ isError }">
          <Input
            v-model="form.minCapacity"
            :label="$t('Minimum capacity')"
            :placeholder="$t('Minimum capacity')"
            :isError="isError"
          />
        </FormItem>
        <FormItem rules="required" v-slot="{ isError }">
          <Select
            v-model="form.type"
            :options="typeOptions"
            :label="$t('Type')"
            :placeholder="$t('Type')"
            :isError="isError"
            is-disabled
          />
        </FormItem>
        <FormItem class="resource-form-dialog__active">
          Active
          <Switcher v-model="isResourceActive" />
        </FormItem>
        <FormItem class="grid-2__full-item">
          <Input
            v-model="form.description"
            :label="$t('Description')"
            :placeholder="$t('Description')"
          />
        </FormItem>
      </div>
      <div class="resource-form-dialog__actions">
        <Button
          is-block
          :is-loading="isSubmitting"
          :is-disabled="invalid || !changed || !!idErrorText || isIdVerifying"
          @click="$emit('submit', form)"
        >
          {{ isEditing ? "Save" : "Create lane" }}
        </Button>
        <Button
          isOutlined
          variant="secondary"
          is-block
          @click="$modal.hide(modalName)"
        >
          Cancel
        </Button>
      </div>
    </Form>
  </CustomModal>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { getResourceById } from "@/store/resources/actions";
import { debounce } from "debounce";

export default {
  name: "ResourceFormDialog",
  props: {
    value: {
      type: Object,
      required: true,
    },
    modalName: {
      type: String,
      default: "resourceFormDialog",
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {},
      typeOptions: [
        {
          value: "Lane",
          name: "Lane",
        },
        // {
        //   value: "Table",
        //   name: "Table",
        // },
        // {
        //   value: "Apartment",
        //   name: "Apartment",
        // },
      ],
      statusOptions: [
        {
          value: true,
          name: "Paused",
        },
        {
          value: false,
          name: "Running",
        },
      ],
      idErrorText: null,
      isIdVerifying: false,
    };
  },
  computed: {
    ...mapState({
      venueId: (state) => state.venues.selectedVenue?.id,
    }),
    isResourceActive: {
      get() {
        return !this.form.isPaused;
      },
      set(newValue) {
        this.form.isPaused = !newValue;
      },
    },
  },
  watch: {
    value(val) {
      this.form = { ...val };
    },
    "form.id": debounce(async function (val) {
      try {
        if (!this.isEditing && this.venueId && val) {
          this.isIdVerifying = true;
          const resource = await getResourceById(val, this.venueId);
          this.idErrorText = resource ? "This ID is already taken" : null;
        }
      } catch {
        this.idErrorText = null;
      } finally {
        this.isIdVerifying = false;
      }
    }, 1000),
  },
  methods: {
    ...mapActions({
      fetchResourceById: "resources/fetchResourceById",
    }),
  },
};
</script>

<style lang="scss">
.resource-form-dialog {
  position: relative;
  padding: 24px;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 48px);
  max-width: 750px !important;
}
</style>

<style lang="scss" scoped>
.resource-form-dialog {
  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  &__active {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto 0;
    padding-top: 22px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: $secondary-500;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (min-width: $media-laptop) {
      flex-direction: row-reverse;
      gap: 40px;
    }
  }
}
</style>
